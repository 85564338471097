










































import { promiseHelper } from '@/helpers/promise-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch, Inject, Prop } from 'vue-property-decorator'
import { round } from 'lodash-es'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import moment from 'moment'
import { IReactionDisposer, reaction } from 'mobx'
import { NO_TIER } from '@/constants'
import { StakingServiceItemViewModel } from '../viewmodels/staking-service-viewmodel'

@Observer
@Component({})
export default class ApyConfigDialog extends Vue {
  @Prop({ default: null }) vm!: StakingServiceItemViewModel
  noTierText = NO_TIER
}
